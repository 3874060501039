// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import {} from "jquery-ujs"
import "cocoon"
import _ from "lodash"
import moment from "moment/moment"
import Player from "@vimeo/player";
import YouTubePlayer from "youtube-player";
import Chart  from "chart.js/auto"
import { getRelativePosition } from 'chart.js/helpers'


require("turbolinks").start()
require("@rails/activestorage").start()

require("bootstrap")
require("@fortawesome/fontawesome-free/js/all")
require("metismenu")
require("jquery-slimscroll")
require("select2")
require("slick-carousel/slick/slick.min")
require("@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4")
require("jquery-ui/ui/widgets/sortable")
require("jquery-cropper/dist/jquery-cropper.min")
require("cropperjs/dist/cropper.min")
require("dm-file-uploader/src/js/jquery.dm-uploader")
require("clipboard/dist/clipboard.min")
require("@vimeo/player/dist/player.min")
require("linkifyjs/dist/linkify.min")
require("linkifyjs/dist/linkify-jquery.min")
require("youtube-player/dist/YouTubePlayer")

require("stylesheets")

window.jQuery = $
window.$ = $
window.Popper = Popper
window.ClipboardJS = ClipboardJS
window.Player = Player
window.YouTubePlayer = YouTubePlayer
window.moment = moment
window.Chart = Chart
window.Chart.helpers = { getRelativePosition }

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("tempusdominus-bootstrap-4")
